export const projectObjOne = {
  rows: [0, 2, 4, 6],
  sectionId: "projectPortfolio",
  img: [
    "/images/Citadel.png",
    "/images/Rainbow.png",
    "/images/OCH.png",
    "/images/OCRCC.png",
    "/images/intervalHouse.png",
    "/images/CTSACT.png",
    "/images/FOE.png",
    "/images/ARC.png",
  ],
  imgLink: [
    "/Projects-Citadel",
    "/Projects-Rainbow",
    "/Projects-OCH",
    "/Projects-OCRCC",
    "/Projects-Interval",
    "/Projects-CTSACT",
    "/Projects-FOE",
    "/Projects-ARC",
  ],
  alt: [
    "Citadel Canine Portfolio",
    "Rainbow Kidschool Portfolio",
    "Operation Come Home Portfolio",
    "Ontario Coalition of Rape Crisis Centres Portfolio",
    "Interval House of Ottawa Portfolio",
    "CTSACT Portfolio",
    "Friends of Earth Portfolio",
    "Amazon Rainforest Conservancy Portfolio",
  ],
  portfolioTitle: [
    "Citadel Canine Society",
    "Rainbow Kidschool",
    "Operation Come Home",
    "Ontario Coalition of Rape Crisis Centres (OCRCC)",
    "Interval House of Ottawa",
    "Centre for Treatment of Sexual Abuse and Childhood Trauma",
    "Friends of Earth",
    "Amazon Rainforest Conservancy",
  ],
  portfolioDate: [
    "October 2020",
    "November 2020",
    "January 2021",
    "January 2021",
    "February 2021",
    "March 2021",
    "March 2021",
    "March 2021",
  ],
  buttonLabel: "Let's Work Together",
  buttonColour: "pinkBtn",
  buttonLink: "/Apply",
};
// export const projectObjOne = {
//   rows: [0,2],
//   sectionId: "portfolio",
//   img: ["/images/Citadel.png","/images/Rainbow.png","/images/OCH.png"],
//   imgLink: ["/Projects-Citadel", "/Projects-Rainbow", "/Projects-OCH"],
//   alt: ["Citadel Canine Portfolio", "Rainbow Kidschool Portfolio", "Operation Come Home Portfolio"],
//   portfolioTitle: ["Citadel Canine Society", "Rainbow Kidschool", "Operation Come Home"],
//   portfolioDate: ["October 2020", "November 2020", "January 2021"],
//   buttonLabel: "Let's Work Together",
//   buttonColour: "pinkBtn",
//   buttonLink: "/Apply",
// }
