// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/SourceCodePro-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Metropolis-Regular.otf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/Metropolis-Bold.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n  overflow-x: hidden; }\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: sans-serif; }\n\n@font-face {\n  font-family: \"SourceCodePro\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\"); }\n\n@font-face {\n  font-family: \"AvenirRoman\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\"); }\n\n@font-face {\n  font-family: \"AvenirBook\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\"); }\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\nspan {\n  font-family: \"SourceCodePro\"; }\n\nstrong {\n  font-family: \"AvenirBook\"; }\n\np,\na,\nsmall {\n  font-family: \"AvenirRoman\"; }\n", "",{"version":3,"sources":["webpack://src/index.scss"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB,EAAA;;AAGpB;EACI,SAAS;EACT,UAAU;EACV,uBAAuB,EAAA;;AAG3B;EACE,4BAA4B;EAC5B,+DAAgE,EAAA;;AAGlE;EACE,0BAA0B;EAC1B,+DAA6D,EAAA;;AAG/D;EACE,yBAAyB;EACzB,+DAA0D,EAAA;;AAG5D;;;;;;;EAOE,4BAA4B,EAAA;;AAG9B;EAAO,yBAAyB,EAAA;;AAEhC;;;EAIE,0BACF,EAAA","sourcesContent":["html,\nbody{\n  overflow-x: hidden;\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: sans-serif;\n  }\n\n@font-face {\n  font-family: \"SourceCodePro\";\n  src: url(\"./fonts/SourceCodePro-Regular.ttf\") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"AvenirRoman\";\n  src: url(\"./fonts/Metropolis-Regular.otf\") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"AvenirBook\";\n  src: url(\"./fonts/Metropolis-Bold.otf\") format(\"opentype\");\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\nspan{\n  font-family: \"SourceCodePro\";\n}\n\nstrong{font-family: \"AvenirBook\"}\n\np,\na,\nsmall\n{\n  font-family: \"AvenirRoman\"\n}\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
